/*resetting css to default value*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/*actual content*/
/********************************************* COMMON ********************************************/

@font-face {
  font-family: HelveticaNeue;
  src: url("/src/assets/fonts/HelveticaNeue-Regular.ttf");
}
@font-face {
  font-family: HelveticaNeue;
  src: url("/src/assets/fonts/HelveticaNeue-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: HelveticaNeue;
  src: url("/src/assets/fonts/HelveticaNeue-Light.ttf");
  font-weight: 200;
}
@font-face {
  font-family: HelveticaNeue;
  src: url("/src/assets/fonts/HelveticaNeue-Bold.ttf");
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6, button  {
  font-family: HelveticaNeue, Helvetica, sans-serif;
  font-weight: bold;
}

p, li {
  font-family: HelveticaNeue;
  font-weight: 100;
  font-size:1.5rem
}
h1 {
  font-size:3.5rem;
}
h2 {
  font-size:2rem;
}
h3 {
  font-size:1.7rem;
}

strong {
  font-weight: bold;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

html{
  font-size: 16px;
  scroll-behavior: smooth;
}

a {
  font-weight: 400;
  font-size: 1rem;
  color:#000 !important;
}

a:hover{
  color:#0076CE !important;
}

.disappearOnMobile {
  display:inline;
}
.preventSelect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
@media screen and (max-width:972px){
  .disappearOnMobile{
    display: none !important;
  }
}

a.alt{
  font-weight: 400;
  font-size: 1rem;
  color:#9a2d2d !important;
}

button {
  color: #000;
  background-color: #fff;
  padding: 2vh 6vh;
  font-weight: 400;
  font-size:1rem;
}

button{
  color: #000;
  background-color: #fff;
  padding: 2vh 6vh;
  font-weight: 400;
  border: #000;
  border-style: solid;
  border-width: 1px;
  font-size:1rem;
  transition-duration: 0.5s;
}
button:hover{
  color:#000;
  background-color: #fafafa;
  text-decoration: underline;
  transition-duration: 0.5s;
}
button.alt{
  color:#fff;
  background-color: #0076CE;
  border-color:#0076CE;
}
button.alt:hover{
  color:#0076CE;
  background-color: #fff;
}
button.acc {
  color:#fff;
  background-color: #0076CE;
}

/********************************************* HEADER ********************************************/
header {
  display: flex;
  width: 100vw;
  position: fixed;
  padding-top: 1vh;
  padding-bottom: 2vh;
  text-align: center;
  height: 4vh;
  top: 0;
  transition: top 0.3s;
  /*background-color: rgba(0,0, 0, 0);*/
  z-index: 99;
}

.accent{
  color: #0076CE;
}

header .logo{
  margin-top: 0.5vh;
}

header img{
  object-fit: contain;
}

header > div{
  padding: 1vh;
}

header div:first-child {
  flex:1;
}

header div:nth-child(2){
  flex:3;
}

header div:last-child{
  flex:1;
}

header ul, header li {
  display:inline;
}

header li {
  padding-left: 1vw;
  padding-right: 1vw;
  font-weight: bold;
  font-size: 1.3rem;
}

header .logo span {
  display:none;
}
header .logo:hover span {
  display:inline-block;

}

/*********************************** HERO ************************************/

.neuHero {
  width: 50vw;
  padding-left: 10vw;
  display: flex;
  gap: 1vh;
  flex-direction: column;
}
@media screen and (max-width:972px){
  .neuHero {
    width: 100vw;
    padding-left: 9vw;
    padding-right: 8vw;
    display: flex;
    gap: 1vh;
    flex-direction: column;
  }
  .neuHero h1 {
    font-size: 30px;
  }
}

.hero {
  padding:0vw;
  width:100vw;
  background-color: #fff;
  position:relative;
}
.hero > div{
  padding: 20vh 10vw;
  padding-top:30vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
}
.mainText > h1{
  padding-bottom: 4vh;
  z-index: 2;
  -webkit-text-stroke: #ffffff 1px;
}

.heroImage {
  position:absolute;
  top:0;
  left: 0vw;
  width:100vw;
  z-index: 0;
}

.mainText {
  text-align: center;
}

.mainText > p{
  font-size:1.5rem;
  font-weight: 100;
  /*background-color: #fff;*/
  z-index: 2;
  width: 60vw;
  padding-bottom: 4vh;
}

.heroButtons {
  padding-top:3vh;
  z-index: 3;
}

.quitplanetary {
  position:fixed;
  top: 70vh;
  left:0vh;
}

.planetTopInfobox{
  background-color: #fff;
}

.planetarydays{
  position:fixed;
  top: 82vh;
  left:-4vh;
  display:flex;
  padding:0;
  margin:0;
  width: fit-content;
  padding:2vh 2vw;
  font-size: 1rem;
}
.planetarydays > span {
  border: #000 solid 1px;
  background-color: #fff;
  padding: 2vh;
}


.hideHover{
  display:block;
  transition:1s;
}

.hidden{
  display:visible;
  transition:1s;
  opacity: 0;
  transition: opacity 0.6s linear;
}

.hero .planetTopInfobox{
  position:fixed;
  padding:0;
  top: 8vh;
  right:-10vw;
}
.planetTopInfobox p{
  font-size: 1.3rem;
  text-align: end;
  width:30vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

@media screen and (max-width:972px){
  .hero .planetTopInfobox{
    position:fixed;
    padding:0;
    top: 5vh;
    left:0vw;
  }
  .planetTopInfobox p{
    font-size: 1.3rem;
    text-align: end;
    width:90vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    background-color: white;
  }

  .quitplanetary {
    position:fixed;
    top: 85vh;
    right:0vh;
  }
  .planetarydays{
    position:fixed;
    top: 75vh;
    left:0vh;
    display:flex;
    padding:0;
    margin:0;
    width: fit-content;
    padding:2vh 2vw;
    font-size: 1rem;
  }
  .planetarydays > span {
    border: #000 solid 1px;
    background-color: #fff;
    padding: 2vh;
  }
  
  #svg-frame{
    text-align:center;
    height:100vh;
    overflow-y: hidden;
  }
  .svgContainer {
    display: flex;
    justify-content: center;
    height:100% !important;
    overflow-y: hidden;
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 2vh !important;
    width: 100vw !important;
    height: 90vh !important;
  }

  .hero .mainText {
    height:fit-content ;
    width: 80vw;
  }
}
.planetInfobox {
  text-align:right;
  font-family: HelveticaNeue;
  font-size: 1.3rem;
  font-weight:100;
}
.planetInfoboxTitle {
  text-align: right;
  font-family: HelveticaNeue;
  font-size: 1.3rem;
  font-weight:100;
}

/********************** ABOUT *************************/

.about {
  background-color: #ffffff;
  z-index:1;
  border-color:#000;
  border-style: solid;
  border-top-width: 7px;
  border-bottom-width: 7px;
  padding: 10vh 10vw;
  display:flex;
  flex-direction: row;
  gap:5vw;
  color:rgb(0, 0, 0);
}
.about article{
  flex-basis:1;
}
.about p {
  margin: 2vh 20vh 2vh 0vw;
  font-size: 1.4rem;
  line-height: 1.8rem;
  max-width: 60vw;
}
.about img{
  flex-basis: 1;
  width: 20vw;
  aspect-ratio: 3 /4;
  border:#000 solid 2px;
}

@media screen and (max-width: 972px) {
  .about{
    padding: 20vh 10vw;
  }
  .about p{
    margin: 0vh 0vh 2vh 0vh;
    max-width: 100vw;
  }
  .about img{
    flex-basis: 1;
    width: 30vw;
    aspect-ratio: 3 /4;
    border:#000 solid 2px;
  }
}

/********************** projects *************************/

#projects{
  padding:15vh 10vw;
  border-top-width: 7px;
  border-style: solid;
  border-color:#000;
  margin-top: 1vh;
  overflow: hidden;
}

#projects h2 {
  padding-bottom: 1vh;
}

#grid{
  background-color: #fff;  
  width:80vw;
  padding-top: 5vh;
}

.post-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:4vh;
}

.card {
  background-color: #fff;
  overflow:hidden;
  padding: 0px;
  z-index: 2;
}



.card .imgContainer {
  height:45vh;
  overflow: hidden;
}
.card img {
  object-fit: cover;
  transition-duration: 5s;
  transition-property: transform;
}

.card:hover img{
  transform: scale(1.1);
  border-color: #000;
  border-width: 2px;
}

@media screen and (max-width:972px){
  .post-list {
    display: grid;
    grid-template-columns: 1fr;
    gap:4vh;
  }
  .card .imgContainer {
    height:30vh;
    overflow: hidden;
  }
}


.card p {
  padding:2vh 0vw; 
  width:fit-content;
  font-size: 1rem;
  font-weight:400;
  margin-left: auto;
  margin-right:auto;
}


.ball {
  content: url("./assets/corgi/ball.png");
  width: 10px;
  height:10px;
  bottom:15px;
  left:60px;
  position:fixed;
}

/* TOREMOVE */
.corgi {
  content: url("./assets/corgi/idle-1.png");
  width: 65px;
  height:65px;
  bottom:0;
  left:0;
  z-index: 99;
  position:fixed;
}
@keyframes corgiidle {
  0%{
    content: url("./assets/corgi/idle-1.png");
  }
  20%{
    content: url("./assets/corgi/idle-2.png");
  }
  40%{
    content: url("./assets/corgi/idle-3.png");
  }
  60%{
    content: url("./assets/corgi/idle-4.png");
  }
  80%{
    content: url("./assets/corgi/idle-5.png");
  }
}
@keyframes corgiidle2 {
  0%{
    content: url("./assets/corgi/idle2-1.png");
  }
  20%{
    content: url("./assets/corgi/idle2-2.png");
  }
  40%{
    content: url("./assets/corgi/idle2-3.png");
  }
  60%{
    content: url("./assets/corgi/idle2-4.png");
  }
  80%{
    content: url("./assets/corgi/idle2-5.png");
  }
}
@keyframes corgisniff {
  0%{
    content: url("./assets/corgi/sniff-1.png");
  }
  13%{
    content: url("./assets/corgi/sniff-2.png");
  }
  26%{
    content: url("./assets/corgi/sniff-3.png");
  }
  39%{
    content: url("./assets/corgi/sniff-4.png");
  }
  52%{
    content: url("./assets/corgi/sniff-5.png");
  }
  65%{
    content: url("./assets/corgi/sniff-6.png");
  }
  78%{
    content: url("./assets/corgi/sniff-7.png");
  }
  84%{
    content: url("./assets/corgi/sniff-8.png");
  }
  100%{
    content: url("./assets/corgi/sniff-1.png");
  }
}

/* CIRCLES */

.circle {
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  border-style: solid;
  border-color: #000;
  border-width: 7px;
}
.circle-sun:hover{
  fill:rgb(243, 240, 62);
}

#svg-frame {
  position:absolute;
  padding:0vh;
  top:0vh;
  left:45vw;
}
/**************************************FORM ***************************************/
#contact{
  padding:15vh 10vw;
  /*border-top-width: 7px;
  border-style: solid;
  border-color:hsl(0, 0%, 0%);*/
  margin-top: 1vh;
  overflow: hidden;
}
.contact-option{
  display:grid;
  grid-template-columns: 1fr 1fr;
  
}

@media screen and (max-width:972px) {
  .contact-option{
    grid-template-columns: 1fr;
  }
  #contact{
    padding: 10vh 5vw;
    margin: 0;
  }

  .contact-regular{
    padding:0 !important;
  }

  .contact-regular p {
    margin-right:10vw;
  }
  .field label span {
    white-space: wrap;
  }

  .heroButtons{
    display: flex;
    flex-direction: row;
  }
  .heroDecorations {
    display:block;
    
  }

}
.form {
  background-color: #eff1f1;
  padding: 5vh 2vw;
  margin-top: 5vh;
  min-height: 60vh;
}

.formCompleted{
  background-color: #eff1f1;
  display:flex;
  flex-direction: column;
  gap:2vh;
  padding: 5vh 2vw;
  margin-top: 5vh;
  min-height: 60vh;
}
.contact-regular{
  background-color: #ffffff;
  padding: 5vh 2vw;
  margin-top: 5vh;
}

.contact-regular a {
  font-size: 1.5rem;
}
.fieldgroup {
  display: flex;
  flex-direction: column;
  gap:4vh;
  padding: 2vh 0vw;
}

.field {
  display:grid;
  grid-template-rows: auto auto;
  gap:1vh;
  font-size: 1.3rem;
  font-weight: 100;
  font-family: Cello Sans;
}
.errorfield {
  color: #9a2d2d;
  font-size: 0.8rem;
  font-weight: bold;
}

.field label span {
  white-space:nowrap;
}

.radiolabel {
  background-color: #e7e7e7;
  padding:1vh 2vw;
  border-radius: 10px;
  border-width:2px;
  border-style: solid;
  border-color:#fff;
  font-size: 1rem;
}
.radiolabel:hover {
  border-color:#0076CE;
  border-width:2px;
  border-style: solid;
}
.radiolabel-selected{
  background-color: #0076CE;
  color:#fff
}

.radio{
  display:none;
}

.contact .radiobuttons {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:2vh;
  margin-top:0.4vh;
  font-size: 1rem;
  font-family: Cello Sans;
}

@media screen and (max-width:992px) {
  html{
    font-size: 14px;
  }
  #svg-frame {
    position:relative;
    bottom:0vh;
    left:0vw;
  }

  .hero > div{
    width: 90vw;
    height: 6vh;
  }
}

.page {
  padding-top:7vh;
}

/************************************** FORM ***************************************/
.SingleProject {
  padding-top: 7vh;
  display:flex;
  flex-direction: column;
  margin-left:auto;
  margin-right:auto;
  max-width: 1200px;
}

.SingleProject .heroSection{
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.SingleProject .heroSection .heroArrow {
  width: 10vw;
  height: 50vh;
}
.SingleProject .heroImg {
  width: 80vw;
  height:50vh;
  /*aspect-ratio: 3 /4;*/
  /*border:#000 solid 7px;*/
}
.ProjectCarousel {
  height: 35vh;
}

@media screen and (min-width:972px){
  .ProjectCarousel {
    height:50vh;
    width: 100vh;
    /*aspect-ratio: 3 /4;*/
    /*border:#000 solid 7px;*/
  }
}

.SingleProject article {
  margin: 10vh 10vw;
  margin-top: 0vh;
  display:flex;
  flex-direction: column;
  gap: 2vh;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5vw;
  font-size:1.2rem;
}


.tags p {
  width:fit-content;
}

.thumbs {
  display: none;
}

.Sidebar {
  background-color: #fff;
  border:#000 solid 1px;
  overflow-y: scroll;
  position:fixed;
  top:0;
  right: 0;
  width: 70vw;
  height: 100vh;
  transition: 0.5s;
}

.SidebarFallback{
  background-color: #00000066;
  position:fixed;
  top:0;
  right: 0;
  width: 100vw;
  height: 100vh;
}

#Footer {
  padding:5vh 10vw;
  border-top-width: 7px;
  background-color: #000;
  border-style: solid;
  border-color:#000;
  margin-top: 1vh;
  overflow: hidden;
  color:#e7e7e7;
  display: flex;
  flex-direction: row;
  gap: 10vw;
}


/******************************* PROJECT PAGE ********************************/
#ProjectPage{
  display: flex;
  flex-direction: column;
  padding:15vh 10vw;
  margin-top: 1vh;
  gap:2vh;
  overflow: hidden;
}

.SingleProject p {
  line-height: 1.2;
}

.SingleProject ul {
  list-style-type: circle;
}
.experienceList li {
  padding-bottom: 2vh;
  line-height: 1.2;
}



/* HERO 2*/
.Hero2{
  max-height: 80vh;
}

.heroText{
  margin-top: 30vh;
  margin-bottom: 20vh;
  margin-left: 20vw;
  margin-right: 20vw;
  display:flex;
  flex-direction: column;
  gap:5vh;
}

.contact-regular p {
  line-height: 4vh;
}